import React from 'react';
import Router from 'next/router';
import { Form, Button, Input, message } from 'antd';
import { ApiPort } from '$ACTIONS/TLCAPI';
import { get, post } from '$ACTIONS/TlcRequest';
import { nameReg, pwdReg } from '$ACTIONS/reg';
import { Cookie } from '$ACTIONS/helper';
// import Geetest from '@/UserSign/Geetest';
import Captcha from '../../components/Captcha';
// import Layout from "@/Layout/other";
import Layout from '@/Layout';
import { getMemberInfo } from '$DATA/userinfo';
import { Cookie as helpCookie } from "$ACTIONS/helper"
global.NamePwdVerify = false;
const { Item } = Form;
class Safehouse extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// OpenGeetest: false,
			Domdone: false,
			challengeUuid: '',
			captchaVisible: false
		};

		global.redirectDomin = this.redirectDomin.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isCaptchaOn = true;
	}

	componentDidMount() {
		// var jqscript = document.createElement('script');
		// jqscript.type = 'text/javascript';
		// jqscript.src = '/cn/Geetest/jquery-3.6.0.min.js';
		// document.querySelectorAll('body')[0].appendChild(jqscript);
		// jqscript.onload = () => {
		// 	var _doc = document.getElementsByTagName('head')[0];
		// 	var js = document.createElement('script');
		// 	js.setAttribute('type', 'text/javascript');
		// 	js.setAttribute('src', '/cn/Geetest/gee-test.js?v=2');
		// 	_doc.appendChild(js);
		// 	js.onload = () => {
		// 		this.setState({
		// 			Domdone: true
		// 		});
		// 	};
		// };
	}

	redirectDomin() {
		get(ApiPort.Domaincheck)
			.then((res) => {
				if (res) {
					if (res.mainSiteURL) {
						window.location.href = res.mainSiteURL;
					}
				}
			})
			.catch((error) => {});
	}

	handleSubmit = (e) => {
		if (e) {
			e.preventDefault();
		}
		const { getFieldValue } = this.props.form;
		this.props.form.validateFields((err, values) => {
			if (!err) {
				if (Cookie.Get('FaileCoun' + getFieldValue('username')) >= 3) {
					if (this.state.challengeUuid == '') {
						this.setState({ captchaVisible: true });
						return;
					}
				}
				this.getCaptchaSwitch(values);
			}
		});
	};
	continueToLogin = (values) => {
		const Postdata = {
			hostName: global.location.origin, // global.location.origin
			grantType: 'password',
			clientId: 'Fun88.CN.App',
			clientSecret: 'FUNmuittenCN',
			username: values.username.trim(),
			password: values.password,
			scope: 'Mobile.Service offline_access',
			appId: 'net.funpodium.fun88',
			siteId: 37,
			deviceSignatureBlackbox: window.E2GetBlackbox
				? window.E2GetBlackbox().blackbox == '' || window.E2GetBlackbox().blackbox == undefined
					? ''
					: window.E2GetBlackbox().blackbox
				: '',
			challengeUuid: this.state.challengeUuid
		};
		this.hide = message.loading('玩命登录中...');

		post(ApiPort.PostLogin, Postdata)
			.then((res) => {
				if (res && res.IS_FEAT_MAINTENANCE) {
					return;
				}
				if (res.isSuccess) {
					this.successLogin(res.result, values.username);
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					showError: error.errors[0].description
				});
				this.hide();
				this.setState({
					challengeUuid: ''
				});
				// let errorList =["MEM00004","MEM00059","MEM00060","MEM00140","CAPTCHA_ERROR"]
				if (this.isCaptchaOn) {
					this.logInputFaile(values.username.trim());
				}
			});
	};

	successLogin(token, userName, isRedirect) {
		/* note: bffscLogin will true; bffLogin bffRefreshToken bffscRefreshToken will false; */
		const IS_BFFSC_LOGIN_RESPONSE = token && Object.prototype.hasOwnProperty.call(token, 'accessToken') && typeof token.accessToken === 'object'; // so far, this should always true at this file.
		if (IS_BFFSC_LOGIN_RESPONSE) {
			token = token.accessToken;
		}
		if (token && token.accessToken != null && token.accessToken != '') {
			this.setState({
				isLogin: true,
				UserName: userName,
				isLoginVisible: false
			});
			Cookie.Delete('FaileCoun' + userName);
			localStorage.setItem('UserName', userName);
			localStorage.setItem('access_token', JSON.stringify('bearer ' + token.accessToken));
			localStorage.setItem('refresh_token', JSON.stringify(token.refreshToken));
			helpCookie.Create('user_session_token', token.accessToken, '', 'Lax', true); // session token
			//获取会员资料
			// getMemberInfo((res) => {
			// 	this.hide();
			// 	Router.push('/cn/');
			// 	// this.setState({
			// 	// 	loadinglogin: false
			// 	// });
			// }, true);
			Router.push('/cn/');
			sessionStorage.setItem('isLogin', true);
		} else {
			this.hide();
			// this.setState({
			// 	loadinglogin: false
			// });
		}
	}

	getCaptchaSwitch = (values) => {
		// get(ApiPort.Captchaswitch).then((res) => {
		// 	if (res) {
		// 		if (res.isCaptchaOn) {
		// 			this.isCaptchaOn = true;
		// 		}
		// 		this.continueToLogin(values);
		// 	}
		// });

		this.checkUserLogin(values);
	};
	checkUserLogin = (values) => {
		const hide = message.loading('玩命登录中...');
		const username = values.username.trim();
		get(ApiPort.Safehouse + `&username=${username}&domain=${window.location.origin}`)
			.then((data) => {
				if (data.isSuccess && data.result) {
					this.continueToLogin(values);
				}

				hide();
			})
			.catch((error) => {
				if (this.isCaptchaOn) {
					this.setState({
						challengeUuid: ''
					});
					this.logInputFaile(username);
				}
				hide();
			});
	};
	logInputFaile = (username) => {
		const name = 'FaileCoun' + username;
		/* 输入错误次数 */
		var count = Cookie.Get(name);
		if (count == null || count == '') {
			//第一次输入错误
			Cookie.Create(name, 1);
		} else {
			var count = Cookie.Get(name);
			let num = ++count;
			if (count <= 2) {
				Cookie.Create(name, num);
			} else {
				Cookie.Create(name, num);
			}
		}
	};
	onMatch = (id) => {
		this.setState(
			{
				challengeUuid: id,
				captchaVisible: false
			},
			() => {
				this.handleSubmit();
			}
		);
	};
	render() {
		const { getFieldDecorator, getFieldValue } = this.props.form;
		const { captchaVisible, showError } = this.state;
		console.log(getFieldValue('username'));
		return (
			<Layout
				status={2}
				Keywords="英超俱乐部赞助商/NBA球星代言娱乐平台/托特纳姆热刺赞助伙伴/纽卡斯尔赞助伙伴/伯恩利官方球衣赞助商/托尼帕克品牌大使/卡西利亚斯品牌大使/科比布莱恩品牌大使/史蒂夫纳什品牌大使/罗比福勒桑德兰品牌大使/刀塔OG战队赞助商
                Description:拥有13年历史实力品牌，NBA球星联合推荐、英超俱乐部合作伙伴，提供最全五大足球联赛等体育赛事，以及多样化游戏娱乐项目，迎战欧洲杯最佳首选APP。"
				description=""
				wrapperClassName="midautumn-bg double"
				title="亚洲首选体育平台，英超热刺纽卡赞助伙伴 "
				setLoginStatus={(v) => {
					this.setState({ isLogin: v });
				}}
				definedHeaderNode={
					<React.Fragment>
						<div className="header-common-distance-wrap" style={{ marginTop: '-114px' }}>
							<div className="maintain-header-wrap" style={{ display: 'flex' }}>
								<div className="common-distance" style={{ display: 'flex', alignItems: 'center' }}>
									<div className="logo-wrap inline-block">
										<img
											src="/cn/img/home/logo@2x.png"
											alt="F88"
											style={{ width: '128px', height: '60px' }}
										/>
									</div>
									<div className="sponsor-wrap inline-block">
										<div className="tlc-title-partner inline-block">
											<img
												src="/cn/img/logo-Sevilla1.png"
												alt="F88"
												style={{
													width: '30px',
													marginTop: '-19px',
													marginRight: '10px'
												}}
												width="30px"
											/>
											<div
												className="tlc-partner-section inline-block"
												style={{ paddingRight: '20px' }}
											>
												纽卡斯尔联足球俱乐部 <br />
												球衣赞助商
											</div>
										</div>
										<div className="tlc-title-partner inline-block">
											<img
												src="/cn/img/logo-Sevilla2x.png"
												alt="F88"
												width="30px"
												style={{
													width: '30px',
													marginTop: '-19px',
													marginRight: '10px'
												}}
											/>
											<div className="tlc-partner-section inline-block">
												热刺足球俱乐部 <br />
												亚洲官方投注伙伴
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</React.Fragment>
				}
			>
				<div id="maintain" className="common-distance-wrap">
					<div className="BoxOther sf">
						<div className="Box_Content">
							<h3>登入</h3>
							{showError && (
								<div className="ErrorShow">
									<small>{showError}</small>
								</div>
							)}

							<Form className="security-login-wrap" onSubmit={this.handleSubmit}>
								<Item label="用户名">
									{getFieldDecorator('username', {
										rules: [
											{
												required: true,
												message: '此栏位不得为空'
											},
											{
												validator: (rule, value, callback) => {
													if (value) {
														if (~value.indexOf(' ')) {
															callback('用户名不得有空格');
														}
														if (!nameReg.test(value)) {
															callback('用户名必须由 5 － 16 位长度的字母或数字组成，中间不得有读音符号、空格或下划线');
														}
													}
													callback();
												}
											}
										]
									})(
										<Input
											size="large"
											className="tlc-input-disabled"
											placeholder="请输入用户名"
											maxLength={50}
											autoComplete="off"
										/>
									)}
								</Item>
								<Item label="密码">
									{getFieldDecorator('password', {
										rules: [
											{
												required: true,
												message: '此栏位不得为空'
											},
											{
												validator: (rule, value, callback) => {
													if (value) {
														if (~value.indexOf(' ')) {
															callback('密码不得有空格');
														}
														if (!pwdReg.test(value)) {
															callback('密码必须包含6-20个字符，字符只限于使用字母和数字。（可以包含 ^#$@ 中的特殊字符）');
														}
													}
													callback();
												}
											}
										]
									})(
										<Input
											size="large"
											type="password"
											className="tlc-input-disabled"
											placeholder="请输入密码"
											maxLength={50}
											autoComplete="off"
										/>
									)}
								</Item>
								<Button size="large" type="primary" id="SubmitGeet" data-gt htmlType="submit">
									登入
								</Button>
							</Form>
						</div>
						<Captcha
							captchaVisible={captchaVisible}
							setCaptchaVisible={(v) => {
								this.setState({ captchaVisible: v });
							}}
							onMatch={this.onMatch}
							getCaptchaInfo={(props) => {
								this.Captcha = props;
							}}
						/>
					</div>
				</div>
			</Layout>
		);
	}
}

export default Form.create({ name: 'Safehouse' })(Safehouse);
